<template>
  <div style="margin-top: 20px;">
    <nav class="navbar-default" style="width: 210px;">
      <ol class="nav isreal_navleft">
        <!-- 合作信息 -->
        <li class="isreal_navleft1" style="padding-left: 30px;">
          {{ $t('m.cooperate_country') }}
        </li>
        <!-- 中东欧合作需求 -->
        <li
          v-for="item in countryOptions"
          :key="item.id"
          class="plan_whline"
          @click="handleMenuClick(item.id)"
        >
          <a
            :class="{'font-corss': activeIndex === item.id}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ item.name }}
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
  //
  export default {
    props: {
      activeIndex: {
        type: Number,
        default: 1
      },
      countryOptions: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      //
      handleMenuClick(val) {
        this.$router.push({ path: '/country', query: {id: val}})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
    .nav {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
  }
  .isreal_navleft1 {
    height: 40px;
    color: #FFFFFF;
    font-size: 16px;
    padding-left: 20px;
    line-height: 40px;
    background-color: #0077cc !important;
  }
  .isreal_navleft {
    li {
      cursor: pointer;
      background-color: #eaeaea;
      a {
        display: block;
        position: relative;
        padding: 10px 15px;
        color: #666666;
        line-height: 16px;
        img {
          vertical-align: middle;
        }
      }
    }
    li:hover {
      background-color: #eeeeee;
    }
    .plan_whline {
      border-bottom: 1px #FFFFFF solid;
    }
  }
  .font-corss {
    color: #0078cb !important;
    border-left: 1px #253d97 solid;
  }
</style>
