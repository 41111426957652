<template>
  <div class="app-container">
    <div class="our_prent">
      <span>{{ countryItem.name }}</span>
      <span style="padding-top: 7px; font-size: 14px; color: #666666;">
        &nbsp;/&nbsp;{{ countryItem.english }}
      </span>
    </div>
    <table style="width: 1200px;">
      <tbody>
      <tr>
        <td style="vertical-align: top;">
          <div class="z_contts">
            <div v-loading="loading" class="isreal_leftcont">
              <div v-html="body" />
            </div>
          </div>
        </td>
        <td style="vertical-align: top;">
          <menu-box
            :country-options="list"
            :active-index="activeIndex"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
//
import { getNationNews } from '@/api/cceec/constant'
import { cooperateCaseList } from '@/api/cceec/cooperate'

import MenuBox from './components/menubox'

export default {
  name: 'Country',
  components: {
    MenuBox
  },
  data() {
    return {
      loading: false,
      list: [],
      countryList: [],
      activeIndex: 1,
      countryItem: null,
      body: ''
    }
  },
  created() {
    this.list = Object.assign([], this.$constant.countryOptions.slice(0, 14))
    if (this.$route.query.id) {
      this.activeIndex = Number(this.$route.query.id)
    }
    const arr = this.list.filter(item => item.id === this.activeIndex)
    if (arr.length) {
      this.countryItem = arr[0]
    }
    console.log(this.countryItem)
    this.getCountryNews()
  },
  methods: {
    getCountryNews() {
      const data = {
        languageType: 1
      }
      this.loading = true
      getNationNews(data).then(res => {
        this.loading = false
        console.log(res)
        this.countryList = res.data
        this.countryList.forEach(item => {
          if (this.countryItem.name === item.nationDkey) {
            this.body = item.body
          }
        })
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    tr {
      margin: 0;
      padding: 0;
      height: 60px;
    }
    td {
      padding: 0;
    }
  }
  input {
    padding: 0;
    color: #666666;
    outline: medium;
  }
  .z_contts {
    width: 950px;
    /*min-height: 500px;*/
    line-height: 30px;
    /*border: 1px solid red;*/
    .wrapper-content {
      margin-top: 10px;
      padding-top: 0px !important;
    }
  }
  .search_form {
    .ssuo {
      width: 950px;
      height: 45px;
      line-height: 45px;
      border: 1px #ccc dashed;
      span {
        font-size: 14px;
        margin-left: 10px;
      }
      .jsmcheng {
        width: 340px;
        height: 21px;
        margin-right: 5px;
        padding-left: 10px;
      }
      .ssou_but {
        width: 60px;
        height: 25px;
        color: #fff;
        line-height: 25px;
        text-align: center;
        outline: none;
        border: none;
        cursor: pointer;
        background-color: #465364;
      }
    }
  }
  .isreal_leftcont {
    margin-top: 30px;
    width: 950px;
    min-height: 400px;
    font-size: 14px !important;
    .comm_visits {
      width: 950px;
      margin-top: 20px;
      tr {
        height: 35px !important;
      }
      ol {
        padding: 0;
        margin: 0 0 0 20px;
        list-style: disc;
      }
    }
  }
  .title_box {
    margin-left: 5px;
    font-size: 14px;
    width: 650px;
    cursor: pointer;
    color: #656565;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
</style>
